<template>
  <div class="container text-center" :style="co_IsMobile ? 'padding-top: 80px' : ''">
    <div class="form-group">
      <div class="log-wrap" :style="co_IsMobile ? 'box-shadow: none;border-radius: 0;' : 'margin-top: 25vh'">
        <h4>Reset Password</h4>
        <div>
          <p style="color: #647b9c; margin-bottom: 20px">
            A reset password link will be sent to your email address
          </p>
          <input
            v-model="d_email"
            type="email"
            class="form-control"
            style="max-width: 300px; margin: auto"
            placeholder="Enter your email"
          />
          <div class="form-group text-center" style="margin-top: 30px">
            <button
              :disabled="d_emailSending"
              class="btn btn-theme"
              type="button"
              @click="m_sendEmail()"
            >
              Reset password
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fb_auth } from "../../lib-core/lib-firebase";
import {ios, android} from 'platform-detect'

export default {
  name: "cmp-loginpassword",
  data() {
    return {
      d_email: "",
      d_emailSending: false,
    };
  },
  computed: {
    co_IsMobile() {
      return ios || android;
    },
  },
  mounted() {
    setTimeout(() => {
      this.$store.commit("md_fire/mt_setLoading", false);
    }, 500);
  },
  methods: {
    m_sendEmail() {
      if (!this.m_validateEmail(this.d_email)) {
        this.m_showDialog("Warning", "Please enter valid email address");
        return;
      }
      this.d_error = null;
      this.d_emailSending = true;
      fb_auth
        .sendPasswordResetEmail(this.d_email)
        .then(() => {
          this.d_emailSending = false;
          this.m_showDialog(
            "Success",
            "Reset password link has been sent to your email.",
            this.m_onEmailSent
          );
        })
        .catch((error) => {
          this.d_emailSending = false;
          // this.d_error = error.message;
          this.m_showDialog(
            "Incorrect email",
            "Please make sure you entered the correct email."
          );
        });
    },
    m_validateEmail(p_email) {
      const l_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return l_regex.test(String(p_email).toLowerCase());
    },
    m_showDialog(p_title, p_message, p_callback) {
      let l_payload = {
        title: p_title,
        message: p_message,
        button: {
          yes: "Ok",
        },
      };
      if (p_callback) l_payload.callback = p_callback;
      this.$confirm(l_payload);
    },
    m_onEmailSent() {
      this.$router.push({ name: "access" });
    },
  },
};
</script>

<style>
</style>